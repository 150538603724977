.search-div {
  position: relative;
  background: #fff;
  height: 47px;
  display: flex;
  border-radius: 30px;
  align-items: center;
}
.search-div input[type="text"] {
  background: transparent;
  border: none;
  width: 80%;
  height: 40px;
  outline: none;
  padding-left: 15px;
}
.search-div input[type="text"]::placeholder {
  color: var(--blue-color);
}
.search-div input[type="text"] {
  color: var(--blue-color);
}
.toggler {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  transition:all 0.5s;
}
.toggler.active {
  transform: rotate(180deg);
}
.toggler img {
  height: 30px;
  height: 30px;
}
.services-dropdown {
  opacity:0;
  display:none;
}
/* .services-dropdown {
  z-index: 99;
  right: 0;
  width: 50%;
  float: right;
  position: relative;

  transition:0.5s;
} */

.services-dropdown.active{
    opacity:1;
    display:initial;
}

.services-dropdown ul li {
  background: #fff;
  margin:0.2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.services-dropdown ul li a:hover{
  background: var(--pink-color);
  cursor: pointer;
}

.services-dropdown ul li a{

  background:#fff;
  height:100%;
  padding: 0.8rem;
  transition:All 0.5s;
  width:100%;

}
.services-dropdown ul li:hover a {
  color: var(--white-color);
}
.services-dropdown a {
  color: var(--blue-color);
  display: block;
  text-align: center;
  transition: all 0.5s;
  width:100%;
  height:100%;
}

@media (max-width: 660px) {
  .search-div {
    height: 40px;
  }

  .services-dropdown {
    width: 70%;
  }

  .services-dropdown a {
    font-size: 1;
  }
  
}

@media (max-width: 408px) {
  .services-dropdown {
    width: 70%;
  }
  .toggler img{
    width:30px;
  }
}
