.pageTitle p{
    color:var(--white-color);
    font-weight:700;
    font-size:var(--page-titles-font);
    text-align:center;
    text-transform:capitalize;
    }
.container{
    display:relative;
}

.arrow-navigator{
    position: absolute;
    top:1.5rem;
}