.sidebar {
    /* width: 200px; */
    padding: 30px;
    box-shadow: 1px 4px 8px rgba(4, 140, 252, 0.7); /* Subtle shadow */
    box-sizing: border-box;
    width: 20%;  /*Sidebar takes 20% of the width */
    height: 100%; /* Makes the sidebar span the full height of the parent */
    margin: 10px;


    
  }
  

  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 15px;
    font-size: 18px;
    color: #314355; /*Darker text color */
  }
  