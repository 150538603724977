@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --bg-color: #0a0212;
  --blue-color: #2300ff;
  --pink-color: hsl(318, 100%, 55%);
  --white-color: #ffffff;
  --font-weight: 700;
  --card-font: 1.3rem;
  --section-font: 1.47rem;
  --small-device: 0.85rem;
  --heading-small-device: 1.7rem;
  --heading-large-devices: 2.1rem;
  --black: #000;
  --page-titles-font: 2rem;
}
/*  --bg-color:#0A0212; */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
}
body {
  background-color: var(--bg-color);
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  position: relative;
}
.btn {
  border: none;
  padding: 0.8rem 6rem;
  border-radius: 30px;
  color: var(--blue-color);
  font-weight: 600;
  cursor: pointer;
}
.sub-title {
  font-size: 2.3rem;
  border-bottom: 2px solid var(--blue-color);
  color: var(--white-color);
  font-weight: var(--font-weight);
  display: block;
}
.sub-heading {
  text-align: center;
  padding: 1rem 0rem;
}

.sub-heading p {
  font-size: var(--heading-large-devices);
  color: var(--white-color);
  display: inline-block;
  margin: 1rem 0rem;
  font-weight: var(--font-weight);
  position: relative;
}
.sub-heading p::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 3px;
  background: var(--blue-color);
}
.book-title {
  font-size: var(--section-font);
  padding: 1rem 0rem;
  margin: 0.5rem 0rem;
  position: relative;
  display: inline-block;
}
.error-message{
  color:#ff004f;
  text-align:center;

}
.arrow-navigator a i {
  font-size: 1.7rem;
  width: 60px;
  height: 60px;
  display: inline;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.5s;
}
img {
  width: 100%;
}
.sub-title-2 {
  font-size: 1.6rem;
  color: var(--blue-color);
}
.flex {
  display: flex;
}

@media (max-width: 644px) {
  .sub-heading {
    text-align: start;
  }
  .sub-heading p {
    text-align: center;
    font-size: var(--heading-small-device);
  }
}


html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

* {
  box-sizing: border-box;
}

main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-family:
    Menlo,
    Monaco,
    Lucida Console,
    Courier New,
    monospace;
}

input[type='button'] {
  border: none;
  background: cornflowerblue;
  color: white;
  padding: 0 12px ;
  margin: 8px;
  font-size: 24px;
  border-radius: 20px;
  cursor: pointer;
}