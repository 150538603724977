.about-hero .w-description{
    color:#fff;
    text-align:center;
    font-size:var(--card-font);
}
.about-hero{
    display:flex;
    flex-direction: column;
}
.about-hero .sub-title{
    display:inline-flex;
}
@media(max-width:644px){
    .about-hero .sub-title{
        background:#000;
    }.about-hero .w-description{
        color:#fff;
        text-align:start;
        font-size:var( --small-device);
    }
    
}