#hero {
  height: 100vh;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
  url("../../images/photofolio_layout/landingpage.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  object-fit: cover;

}
#hero .container {
  height: 100%;
  position: relative;
}
#got-top{
 position:fixed; 
 bottom:4rem;
 right:0;
 font-size: 3.5rem;
 z-index:9;
 cursor: pointer;
}
.content {
  color:var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin-top: 0rem;
}
.content-title {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
}
.content .btn {
  margin-top: 1rem;
  transition: all 0.3s;
  color: var(--blue-color);
  background-color: #fff;
}
.content .btn:hover {
  background: var(--pink-color);
  color: var(--white-color);
}
.content .social-media-icons {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 6rem;
  flex-direction: row-reverse;
  max-width:300px;
  display: flex;
  top: 0;
  justify-content: space-between;
  margin-top:4rem;
  align-items:center;
}
.content .social-media-icons .columns {
  flex-basis: 50%;
}
.content .social-media-icons .columns:nth-child(2) {
  right: 0;
  display: flex;
}
.content .social-media-icons li i{
  width:50px;
  height:50px;
  background:none;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  border-radius:50%;
  border:2px solid transparent;
  cursor: pointer;
  font-size:1.3rem;

  transition:all 0.5s;

}
.content .social-media-icons li i:hover{
  box-shadow:0 0 5px var( --blue-color),0 0 5px #fff,0 0 15px var( --blue-color),0 0 30px var( --blue-color);
}





.content .social-media-icons i {
  font-size: 2.4rem;
  margin: 0.6rem 0rem;
  color: #fff;
}




@media(min-width:1300px){

  #got-top{
    right:2.5rem;
  }


}





@media (max-width: 856px) {
  .content-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 710px) {
  .content-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 678px) {
  .content-title {
    font-size: 1.5rem;
  }
  #hero {
    height: 100vh;
  }
}

@media (max-width: 628px) {
  .content .social-media-icons {
    position: relative;
    flex-direction: row;
    align-items: center;
  }
  .content .social-media-icons ul {
    display: flex;
  }
  .content {
    top:50%;
  }
  .content .social-media-icons ul li {
    margin: 8px;
  }
 
}

@media(max-width:500px){
  #got-top{
    display:none;
   }
}
@media (max-width: 370px) {
  .content .btn {
    width: 150px;
    padding: 0;
    padding: 0.4rem;
  }
}
