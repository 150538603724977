.viewFile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.loading {
  font-size: 18px;
  text-align: center;
}

.error {
  font-size: 18px;
  color: red;
  text-align: center;
}

.newsletterTable {
  width: 100%;
  border-collapse: collapse;
}

.newsletterTable th,
.newsletterTable td {
  padding: 8px;
  border: 1px solid #ddd;
}

/* Set a fixed height for the table container */
.tableContainer {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
}

/* Optional: Style for the scrollable area */
.scrollableArea {
  padding: 10px;
}

/* ViewCsv.css */
.newsletterTable tbody {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
}

/* ViewCsv.css */
.newsletterTable td {
  color: white;
}

/* ViewCsv.css */
.newsletterTable th {
  color: blueviolet;
  text-align: center;
}

.newsletterTable th {
  text-align: left;
}