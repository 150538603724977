.grid{
    display:grid;
    grid-template-columns:repeat(2,1fr);
    grid-gap:3rem;
    margin-top:3rem;
}
.section-b .card .description{
    text-align:start;
}
.section-b .grid .card{
    border:2px solid var(--blue-color);
    position: relative;
    max-height:280px;
}
.section-b .grid .card .img-wrapper{
    width:100%;
    position: relative;
    top:-10px;
    right:-10px;
    height:100%;
    transition:all 0.5;
    overflow:hidden;
}
.section-b .grid .card img:hover{
    transform:scale(1.1);
    overflow:hidden;
    cursor: pointer;
}
.card-text{
    color:#fff;
    position:absolute;
    z-index:9;
    bottom:50%;
    right:2rem;
}
.card-text p{
    font-size:1.15rem;
}

@media(max-width:644px){

}


@media(max-width:640px){
    .grid{
        grid-template-columns:repeat(1,1fr);
    }
}
@media(max-width:468px){
    .section-b .grid .card p{
        font-size:var(--small-device);
    }
}