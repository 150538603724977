/* Admin Dashboard Styles */

.dashboard{
  display: flex;
  flex-direction: row;
  height: 80vh;
}

.dashboard-section {
  /* border: 1px solid blue; */
  padding: 10px;
    display: flex;
    width: 80%;
    height: 100%;
    box-sizing: border-box;
  }

  
  .dashboard-section .email-list {
    margin-top: 1rem;
    border-top: 1px solid var(--white-color);
    padding-top: 1rem;
  }
  
  @media (max-width: 644px) {
    .dashboard-section h1 {
      font-size: var(--heading-small-device);
    }
    .dashboard-section h2 {
      font-size: 1.25rem;
    }
    .dashboard-section button,
    .dashboard-section input[type="text"] {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  

  /*  video center styles  */
  .video-center, .portfolios, .dashboard-section__email{
    position: relative;
    
    flex-grow: 1;
    height: 80vh;
    overflow-y: auto;
  }
  .video-center::-webkit-scrollbar,
  .portfolios::-webkit-scrollbar,
  .dashboard-section__email::-webkit-scrollbar {
    display: none; 
  }
  

.video-library-header{
  position: sticky;
  top: 0px; 
  left: 0;
  right: 0;
  z-index: 1000;
}

.dashboard-section h1 {
  flex: 1;
  float: right;
  font-size: var(--heading-large-devices);
  color: var(--white-color);
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.dashboard-section section {
  background-color: var(--black);
  /* border: 1px solid var(--blue-color); */
  padding: 1.5rem;
  /* margin-bottom: 1rem; */
  border-radius: 10px;
  color: var(--white-color);
}

.dashboard-section h2 {
  font-size: 1.5rem;
  border-bottom: 1px solid var(--blue-color);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: var(--blue-color);
}

button {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  transition: background-color 0.3s;
  border-radius: 20px;
}

button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
}

.dashboard-section input[type="text"] {
  padding: 0.75rem;
  width: calc(100% - 3rem);
  margin-right: 1rem;
  border-radius: 20px;
  /* border: 1px solid var(--blue-color);/ */
  margin-bottom: 1rem;
}

.highlight {
border: 1px solid red;
background-color: #f7dddd;
}
#embedVideoModal{ 
  margin: 5px 0;
  padding: 20px;
  color: white;
  /* box-shadow: 1px 4px 6px rgba(4, 140, 252, 0.4); Subtle shadow */
  /* position: sticky;
  top: 234px; 
  left: 0;
  right: 0; */
  /* z-index: 1000; */
  background-color: #03011d;
  border-radius: 20px;
  cursor: pointer;
}
#embedVideoModal .close-icon { 
  font-size: 2rem;
  color: #c82333;
}
/*--------------------------------------------------video center styles --------------------------*/

/* video library and video card styles */

.video-library {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 10px;
  position: relative;
}
/* Video Card */
.video-card {
  background-color: var(--black);
  /* border: 1px solid var(--blue-color); Use different colors during development */
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 100%;
  transition: transform 0.5s;
  padding: 15px;
  width: 250px; /* Fixed width for each video card */
  text-align: center;
  box-shadow: 1px 4px 6px rgba(4, 140, 252, 0.4); /* Subtle shadow */
}
.video-card:hover {
  transform: scale(1.05);
  box-shadow: 2px 7px 6px rgba(27, 151, 253, 0.733); /* Subtle shadow */
  transition: transform 0.7s;
}

.video-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  /* border: .5px solid var(--white-color); Visual aid during development */
}
.video-card h3 {
  font-size: 1rem;
  color: rgba(182, 183, 187, 0.87);
  text-align: center;
  margin-top: 0.5rem;
  word-wrap: break-word;
  text-overflow:ellipsis;
  /* white-space: nowrap;  */
  max-width: 100%
}

/* Styling for Buttons */
.video-card button {
  /* margin-top: 10px; */
  display: inline-block;
  padding: 8px 12px;
  margin: 10px 0;
  background-color: #006bf7; 
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.video-card button:hover {
  background-color: #0d066e; 
  box-shadow: 2px 7px 6px rgba(217, 232, 245, 0.5); /* Subtle shadow */
 
}

.video-card button.delete {
  background-color: #dc3545; /* Red for delete button */
}

.video-card button.delete:hover {
  background-color: #c82333; /* Darker red on hover */
}





/* --------------------portfoliom Manager styling------------------------------- */
/* Portfolios Section */
.portfolios { 
  padding: 1px;
  /* background-color: #f8f9fa; Light background color   f */
  border-radius: 8px;
}

.portfolios h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Category Videos Layout */
.accordion-content{
  display: none;
}
.accordion-content.active {
  /* Show content when active */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 10px;
  position: relative;
}
/* ------------------------------------------------- */


/* Category Title */
.accordion-header {
  display: flex;
  font-size: 20px;
  color: #314355; /*Darker text color */
  cursor: pointer;
  padding: 20px;
  margin: 10px 20px;
  justify-content: space-between;

  /* background-color: #007bff; */
  /* border: 1px solid rgb(21, 0, 255); */
  outline: none;
  box-shadow: 1px 4px 6px rgba(4, 140, 252, 0.4); /* Subtle shadow */
  transition: box-shadow 1s, transform 1s; /* Transition both box-shadow and transform */


}


.accordion-header:hover {
  transform: scale(1.05); /*add a slight zoom effect*/
  box-shadow: 2px 7px 6px rgba(27, 151, 253, 0.733); /* Subtle shadow */
}
.accordion-header.active {
  background-color: #042d58; /* Darker shade when active */
}
.accordion-icon {
  transition: transform 0.5s; /* Smooth rotation for the icon */
  color: rgb(3, 50, 25);

  
}

.accordion-header.active .accordion-icon {
  transform: rotate(180deg); /* Rotate icon when active */
  color: rgba(75, 3, 3, 0.792);
}



/* ---------------------------------------------page responsive design */
/* Responsive Grid for Video Library */
@media (max-width: 768px) {
  .video-library {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .video-card {
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .video-library {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .video-card {
    min-width: 150px;
  }
}
/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .category-videos {
    justify-content: center; /* Center the videos on smaller screens */
  }

  .video-card {
    width: 100%;
  }
}