.photofolioGrid .grid{
    display:grid;
    grid-template-columns:repeat(3,1fr);
    margin-bottom:7rem;
}
.photofolioGrid .grid .card-port{
    color:#fff;
}
.photofolioGrid .description, .photofolioGrid .card-buttons{
    margin:0 1rem;
}
.photofolioGrid .description{
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction:column;
    margin-bottom:1rem;
}
.photofolioGrid .card-buttons button{
    padding:0.5rem 1rem;
    width:120px;
    border:none;
    color:var(--blue-color);
    transition:all 0.5s;
    cursor: pointer;
    border-radius:30px;

}
.photofolioGrid .card-buttons button:hover{
    padding:0.5rem 1rem;
    width:120px;
    border:none;
    color:var(--blue-color);
    cursor: pointer;
    border-radius:30px;
    color:#fff;
    background:var(--blue-color);
}

@media(max-width:948px){
    .photofolioGrid .grid{
        justify-content:center;
        grid-template-columns: repeat(2,1fr);
    }
    .photofolioGrid .card-buttons button{
        width:100px;
    }
}



@media(max-width:532px){
    .photofolioGrid .card-buttons button{
        width:90px;
    }
}
@media(max-width:486px){
    .photofolioGrid .grid{
        justify-content:center;
        grid-template-columns: repeat(1,1fr);
    }
    .photofolioGrid .card-buttons button{
        width:150px;
    }
    .photofolioGrid .grid .card-port{
        padding:2rem;
    }
}
@media(max-width:434px){
    .photofolioGrid .card-buttons button{
        width:130px;
    }
}
@media(max-width:402px){
    .photofolioGrid .card-buttons button{
        width:100px;
    }
}