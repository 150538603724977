.photofolio-navbar ul li{
    background:#Fff;
    padding:0.7rem 2rem;
    border-radius:30px;
    color:var(--blue-color);
}
.photofolio-navbar ul li a{
    color:var(--blue-color);
    transition:all 0.5s;
}
.photofolio-navbar ul li:hover{
    background:var(--blue-color);;
}
.photofolio-navbar ul li:nth-child(1){
    background:var(--blue-color);
}
.photofolio-navbar ul li:nth-child(1) a{
    color:#FFF;
}
.photofolio-navbar ul li:hover a{
    color:#fff;
}


@media(max-width:792px){
    .photofolio-navbar .container ul{
        overflow-x:scroll;
        margin-bottom:2rem;
    }
}