#banner-card{
  position:relative;
  height: 40vh;
  width:100%;
}

.banner-image{
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
}


#banner-card .banner-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  /* background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)); */
  z-index: 9;
  display: flex;
  align-items: end;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  font-size: 3rem;
  font-weight: 600;
  padding: 10px;
}

#banner-card p{
  color:#ffffff;

}

.video-grid {
  max-width: 1200px;
  padding: 1rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  /* border: 1px solid red; */
  
}

.isloading{
  color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 6px rgba(4, 140, 252, 0.4); /* Subtle shadow */

/* text-align: center; */
}


/* Position Card */
.position-card {
  background-color: var(--black);
  /* border: 1px solid var(--blue-color); Use different colors during development */
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 100%;
  transition: transform 0.5s;
  padding: 15px;
  width: 250px; /* Fixed width for each video card */
  text-align: center;
  box-shadow: 1px 4px 6px rgba(4, 140, 252, 0.4); /* Subtle shadow */
}

.position-card:hover {
  transform: scale(1.05);
}

.position-card-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.position-card h3 {
  font-size: 1rem;
  color: rgba(182, 183, 187, 0.87);
  text-align: center;
  margin-top: 0.5rem;word-wrap: break-word;
  max-width: 100%;
  text-overflow:ellipsis;
}

/* Responsive Grid for Position Card Library */
@media (max-width: 768px) {
  .position-card-library {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .video-grid {
   
    grid-template-columns: repeat(auto-fill, minmax(127px, 1fr));
    gap: 1rem;
   
    
  }

  .position-card {
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .position-card-library {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .position-card {
    min-width: 150px;
  }
}
