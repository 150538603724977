.news-letter .book-title{
    color:#FFF;
    display:block;
    text-align:center;
    margin-top:4rem;
}
.news-letter-form{
    display:flex;
    align-items:center;
    text-align:center;
    justify-content:space-between;
    margin-bottom:2.4rem;
    height:57px;
    border-radius:30px;
    max-width:570px;
    cursor: pointer;
    margin:0 auto;

}
.news-letter-form input{
    background:transparent;
    border:none;
    height:40px;
    outline:none;
}
.news-letter-form{
    background:var(--white-color);
  
}
.news-letter-form input[type=email]{
    flex-basis:67%;
    padding-left:1rem;
    background:transparent;
    color:var(--blue-color);
    font-weight:300;
    font-size:1rem;
}
.news-letter-form input[type=email]::placeholder{
    color:var(--blue-color);
}
.news-letter-form input[type=submit]{
    flex-basis:26%;
    background:var( --blue-color);
    color:#FFF;
    font-weight:500;
    font-size:1.16rem;
    border-radius:30px;
    margin-right:10px;
    cursor: pointer;
    border:2px solid var( --blue-color);
    transition:all 0.5s;
}
.news-letter-form input[type=submit]:hover{
    border:2px solid var( --blue-color);
    transform:scale(1.1);
    color:var(--white-color);
}

@media(max-width:468px){
    .news-letter-form{
        width:100%;
    }.news-letter-form input[type=submit]{
        font-size:var(--small-device);
    }
}

@media(max-width:392px){
    .news-letter-form{
        height:45px;
    }
    .news-letter-form input[type=submit]{
        height:36px;
        font-size:0.8rem;
    }
}


@media(max-width:354px){

    .news-letter-form input[type=email]{
        flex-basis:65%;
    }
    .news-letter-form input[type=submit]{
        flex-basis:30%;
    }

}