.registerForm{
    width:96%;
    max-width:800px;
    margin:0 auto;
    padding:1.4rem;
    background:#fff;
    margin-top:5rem;
}

.container{
    height:100%;
}

input,
select{
    height:45px;
    border:none;
    outline:none;
    margin:1rem 0rem;
    padding:4px;
    cursor: pointer;
}

.registerForm input{
    width:100%;
    background:#ccc;
}
.registerForm select{
    width:100%;
    background:#ccc;

}

input[type=submit]{
    background:var(--blue-color);
    color:var(--white-color);
    font-size:1.2rem;
    cursor: pointer;
}
input[type=submit]:hover{
    background:rebeccapurple;
}
.registrationTitle{
    text-align:center;
    font-size:2rem;
    font-weight:700;
    color:var(--blue-color);
}