.book-btn{
    background:var( --white-color);
    color:var(--blue-color);
    border:none;
    font-size:var( --card-font);
    border-radius:30px;
    margin:2rem auto;
    cursor:pointer;
    display:block;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    max-width:300px;
    padding:0.5rem 0rem;
    transition:all 0.5s;
    
}
.book-btn:hover{
    background:var(--blue-color);
    color:var(--white-color);
}

.book-btn.portfolio-btn{
    padding:0.2rem;
    max-width:400px;
}


.book{
    color:#fff;
}
.book .rows{
    margin:2rem 0rem;
}
.book .book-description{
    font-size:var( --card-font);
    margin:1.5rem 0rem;
}
.book .book-title::before{
    content: "";
    position:absolute;
    width:180px;
    height:3px;
    background:var(--blue-color);
    bottom: 0;
    left:-1rem;
}

.book .section-li p{
    font-size:var( --card-font);
    margin:1rem 0rem;
}




@media(max-width:644px){
    .book .book-description{
        font-size:var(--small-device);
        margin:1.5rem 0rem;
    }
}